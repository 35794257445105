import Vue from "vue";
import store from "@/store";

import { forEachCartItem } from "~/service/ebsn.js";

import get from "lodash/get";
import forEach from "lodash/forEach";
import join from "lodash/join";
import round from "lodash/round";

export default {
  data() {
    return { inizialized: null };
  },
  setInitialized(value) {
    this.inizialized = value;
  },
  getInitialized() {
    return this.inizialized;
  },
  configureCustomMap() {
    this.inizialized = true;
    Vue.$gtag.customMap({
      dimension1: "store",
      dimension2: "warehouse",
      dimension3: "deliveryService",
      dimension4: "client"
    });
    // Vue.$gtag.customMap({ });
    // Vue.$gtag.customMap({ });
    // Vue.$gtag.customMap({ });
    Vue.$gtag.set({
      dimension1: global.config.defaultStoreName,
      dimension4: typeof cordova !== "undefined" ? "app" : "site"
    });
    Vue.$gtag.config({ debug_mode: true });
  },
  initCustomDimension(cart) {
    if (this.inizialized == true) {
      Vue.$gtag.set({
        userId: cart.user.userId,
        anonymize_ip: true,
        dimension1: cart.store.name,
        dimension2: cart.warehouse.name,
        dimension3: this.decodeDeliveryService(cart),
        dimension4: typeof cordova !== "undefined" ? "app" : "site"
      });
    }
  },
  purchase(order) {
    try {
      const total = this.round(order.grossTotal);
      if (this.inizialized == true) {
        const products = this.getItems(order);
        let payload = {
          event_category: "ecommerce",
          transaction_id: order.orderId,
          affiliation: this.decodeAffiliation(order),
          value: total,
          currency: "EUR",
          tax: this.round(order.taxTotal),
          shipping: this.round(order.deliveryFee),
          items: products,
          order: order
        };
        global.EventBus.$emit("purchase", payload);

        Vue.$gtag.event("purchase", payload);
      }
      if (Vue.analytics && Vue.analytics.fbq) {
        Vue.analytics.fbq.event("Purchase", { currency: "EUR", value: total });
      }
    } catch (err) {
      console.log(err);
    }
  },
  refund(order) {
    if (this.inizialized == true) {
      const products = this.getItems(order);

      Vue.$gtag.event("refund", {
        event_category: "ecommerce",
        transaction_id: order.orderId,
        affiliation: this.decodeAffiliation(order),
        value: this.round(order.grossTotal),
        currency: "EUR",
        tax: this.round(order.taxTotal),
        shipping: this.round(order.deliveryFee),
        items: products
      });
    }
  },
  beginCheckout(cart) {
    try {
      if (this.inizialized == true) {
        const products = this.getItems(cart);
        let payload = {
          event_category: "ecommerce",
          items: products,
          value: this.round(cart.grossTotal)
        };

        global.EventBus.$emit("beginCheckout", payload);

        Vue.$gtag.event("begin_checkout", payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  checkoutProgress(cart) {
    try {
      if (this.inizialized == true) {
        const products = this.getItems(cart);
        let payload = {
          event_category: "ecommerce",
          items: products,
          value: this.round(cart.grossTotal)
        };
        global.EventBus.$emit("checkoutProgress", payload);

        Vue.$gtag.event("checkout_progress", payload);
      }
    } catch (err) {
      console.log(err);
    }
  },
  addPaymentInfo() {},
  checkoutOptions(step, option, value) {
    try {
      if (this.inizialized == true) {
        Vue.$gtag.event("set_checkout_option", {
          event_category: "ecommerce",
          checkout_step: step,
          checkout_option: option,
          value: value
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  viewPromotions(proposals, slot) {
    try {
      if (this.inizialized == true) {
        let promotions = [];
        forEach(proposals, value => {
          promotions.push({
            id: value.id,
            name: value.name,
            creative_name: this.getFileName(value.img),
            creative_slot: slot
          });
        });
        Vue.$gtag.event("view_promotion", {
          event_category: "ecommerce",
          promotions: promotions
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  clickPromotion(proposal, slot) {
    try {
      if (this.inizialized == true) {
        let promotions = [
          {
            id: proposal.id,
            name: proposal.name,
            creative_name: this.getFileName(proposal.img),
            creative_slot: slot
          }
        ];
        Vue.$gtag.event("select_content", {
          event_category: "ecommerce",
          content_type: "promotion",
          promotions: promotions
        });
      }
    } catch (err) {
      console.log(err);
    }
  },
  addToCart(items, listName, listPosition) {
    if (this.inizialized == true) {
      const products = items.map(i => this.getItem(i, listName, listPosition));

      Vue.$gtag.event("add_to_cart", {
        event_category: "ecommerce",
        items: products
      });
    }
  },
  removeFromCart(items, listName, listPosition) {
    try {
      if (this.inizialized == true) {
        const products = items.map(i =>
          this.getItem(i, listName, listPosition)
        );

        Vue.$gtag.event("remove_from_cart", {
          event_category: "ecommerce",
          items: products
        });
      }
    } catch (err) {
      console.log(err);
    }
  },

  addProductToList(items, listName, listPosition) {
    if (this.inizialized == true) {
      console.log(listName);
      const products = items.map(i => this.getItem(i, listName, listPosition));

      console.log(products);
      Vue.$gtag.event("add_to_wishlist", {
        event_category: "ecommerce",
        items: products
      });
    }
  },
  removeProductToList(items, listName, listPosition) {
    if (this.inizialized == true) {
      const products = items.map(i => this.getItem(i, listName, listPosition));

      console.log(products);
      Vue.$gtag.event("remove_from_wishlist", {
        event_category: "ecommerce",
        items: products
      });
    }
  },
  viewProducts(items, listName, listOffset) {
    if (this.inizialized == true) {
      let offset = 1 + listOffset;
      let name = listName || "categoria_3";
      const products = items.map((i, index) =>
        this.getItem(i, name, index + offset)
      );

      Vue.$gtag.event("view_item_list", {
        event_category: "ecommerce",
        items: products
      });
    }
  },
  viewProductDetail(product) {
    if (this.inizialized == true) {
      const products = [this.getItem(product)];
      Vue.$gtag.event("view_item", {
        event_category: "ecommerce",
        content_type: "product",
        items: products
      });
    }
  },
  clickProduct(product, listName, listPosition) {
    if (this.inizialized == true) {
      const products = [this.getItem(product, listName, listPosition)];
      Vue.$gtag.event("select_content", {
        event_category: "ecommerce",
        content_type: "product",
        list_name: listName,
        list_position: listPosition,
        items: products
      });
    }
  },
  selectWarehouse() {
    if (this.inizialized == true) {
      Vue.$gtag.event("warehouseChange");
    }
  },
  selectStore(cart) {
    if (this.inizialized == true) {
      this.initCustomDimension(cart);
      Vue.$gtag.event("store_change", {
        event_category: "ecommerce",
        event_label: "method",
        value: cart.store.name
      });
    }
  },
  selectAddress(cart) {
    if (this.inizialized == true) {
      this.initCustomDimension(cart);
      Vue.$gtag.event("address_change", {
        event_category: "ecommerce",
        event_label: "method",
        value: cart.shippingAddress.addressName
      });
    }
  },
  selectTimeslot(timeslotId) {
    if (this.inizialized == true) {
      Vue.$gtag.event("timeslot_change", {
        event_category: "ecommerce",
        value: timeslotId
      });
    }
  },
  login(cart) {
    if (this.inizialized == true) {
      this.initCustomDimension(cart);
      Vue.$gtag.event("login", {
        event_category: "engagement",
        event_label: "method"
      });
    }
  },
  logout() {
    if (this.inizialized == true) {
      Vue.$gtag.set({ userId: null });
      Vue.$gtag.event("logout", {
        event_category: "engagement",
        event_label: "method"
      });
    }
  },
  signUp(method) {
    if (this.inizialized == true) {
      Vue.$gtag.event("sign_up", {
        event_category: "engagement",
        event_label: method
      });
    }
  },
  share(method) {
    if (this.inizialized == true) {
      Vue.$gtag.event("share", {
        event_category: "engagement",
        event_label: method
      });
    }
  },
  search(query) {
    if (this.inizialized == true) {
      Vue.$gtag.event("search", {
        event_category: "engagement",
        value: query
      });
    }
  },
  getItems(order) {
    if (this.inizialized == true) {
      let items = [];
      forEachCartItem(order, item => {
        items.push(this.getItem(item));
      });
      return items;
    }
  },
  getFileName(url) {
    var n = url.lastIndexOf("/");
    var result = url.substring(n + 1);
    return result;
  },
  getItem(item, listName, listPosition) {
    let product = item.product ? item.product : item;
    let analyticsItem = {
      id: product.code + "-" + product.codeVariant,
      name: this.getProductName(product),
      brand: product.shortDescr,
      category: this.getCategoryName(product.categoryId),
      variant: product.description
    };
    if (listName) {
      analyticsItem.list_name = listName;
    }
    if (listPosition) {
      analyticsItem.list_position = listPosition;
    }
    if (item.product) {
      (analyticsItem.quantity = this.getItemQuantity(item)),
        (analyticsItem.price = this.getItemPrice(item));
    }
    return analyticsItem;
  },
  getItemPrice(item) {
    if (this.inizialized == true) {
      if (item.grossTotal) {
        return Math.abs(
          this.round(item.grossTotal / this.getItemQuantity(item))
        );
      } else {
        return item.product.price;
      }
    }
  },
  getItemQuantity(item) {
    if (this.inizialized == true) {
      return item.quantity ? item.quantity : 1;
    }
  },
  getProductName(product) {
    if (this.inizialized == true) {
      var params = [product.name, product.descr, product.shortDescr];
      return join(params, " ");
    }
  },
  getCategoryName(categoryId) {
    let category = store.getters["category/lookupCategory"](categoryId);
    return category?.name;
  },
  round(price) {
    if (this.inizialized == true) {
      return round(price, 2);
    }
  },
  decodeAffiliation(cart) {
    if (this.inizialized == true) {
      if (cart.shippingAddress.addressTypeId === 3) {
        return get(cart, "shippingAddress.addressName", "Drive");
      } else {
        return get(cart, "warehouse.name", "Home");
      }
    }
  },
  decodeDeliveryService(cart) {
    if (this.inizialized == true) {
      if (cart.shippingAddress.deliveryServiceId === 1) {
        return "Drive";
      } else if (cart.shippingAddress.deliveryServiceId === 2) {
        return "Home";
      } else if (cart.shippingAddress.deliveryServiceId === 3) {
        return "Rider";
      } else {
        return "None";
      }
    }
  },
  emptyCart() {
    if (this.inizialized == true) {
      Vue.$gtag.event("emptyCart");
    }
  }
};
